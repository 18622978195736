import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  Injector,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TenantLayoutModule } from '@sidkik/tenant-layout';
import { RoutingModule } from './routing.module';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { initializeApp, provideFirebaseApp, getApp } from '@angular/fire/app';
import {
  AppConfig,
  APP_CONFIG,
  GlobalModule,
  SpanTypes,
  TraceService,
  ANALYTICS_IS_SUPPORTED,
  APP_CHECK_DEBUG_TOKEN,
  storageEmulatorURL,
  CloudflareProviderOptions,
  EventsService,
} from '@sidkik/global';
import {
  AppCheck,
  CustomProvider,
  getToken,
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import { AuthzModule } from '@sidkik/authz';
import { UIModule } from '@sidkik/ui';
import { CommonModule, DOCUMENT } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DbModule } from '@sidkik/db';
import { appVersion } from './app.version';
import {
  provideAnalytics,
  getAnalytics,
  Analytics,
} from '@angular/fire/analytics';
import {
  provideStorage,
  getStorage,
  connectStorageEmulator,
} from '@angular/fire/storage';
import hash from 'object-hash';
import { appCheckError } from './services/app-check-user-error';
import { installPatch } from './patches/nested-drag-drop-patch';

// Add this function to initialize the patch
export function initializeCdkPatch() {
  return () => {
    console.log('Installing CDK nested drag-drop patch');
    installPatch();
  };
}

const globalErrorReporterFactory = (injector: Injector) =>
  new GlobalErrorHandler(injector);
@Injectable()
class GlobalErrorHandler implements ErrorHandler {
  retryCount = 0;
  eventsService!: EventsService; // = inject(TraceService);
  private s = new Set<string>();

  constructor(injector: Injector) {
    // needs event tick
    setTimeout(() => {
      this.eventsService = injector.get<EventsService>(EventsService);
      console.log('app:app.module', 'eventsService', this.eventsService);
    });
  }
  handleError(error: Error) {
    const h = hash.MD5(error);
    if (this.s.has(h)) {
      logger.error('app:app.module', 'already handled', error);
      return;
    }

    if (!this.eventsService) {
      // try this again
      return setTimeout(() => {
        this.retryCount++;
        if (this.retryCount > 3) {
          logger.error(
            'app:app.module',
            'failed to initialize eventsService',
            error
          );
          return;
        }
        this.handleError(error);
      }, 1000);
    }

    this.s.add(h);

    logger.debug('app:app.module', 'tracking error', error);
    return this.eventsService.trackError(error);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Full,
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionSerializability: true,
          // strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateSerializability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    DbModule,
    AuthzModule.forRoot({
      authGuardFallbackURL: '/auth/login',
      authGuardLoggedInURL: '/',
      guardProtectedRoutesUntilEmailIsVerified: false,
    }),
    TenantLayoutModule,
    GlobalModule,
    UIModule.forRoot(),
    RoutingModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: globalErrorReporterFactory,
      deps: [Injector],
    },
    provideFirebaseApp((injector: Injector) => {
      const appConfig: AppConfig = injector.get<AppConfig>(APP_CONFIG);
      const primary = initializeApp(appConfig.firebase);
      initializeApp(environment.secondaryProject, 'secondary');
      return primary;
    }),
    provideAnalytics((injector: Injector) => {
      const isSupported = injector.get<boolean>(ANALYTICS_IS_SUPPORTED);
      if (!isSupported) {
        return {} as Analytics;
      }
      try {
        const secondary = getApp('secondary');
        return getAnalytics(secondary);
      } catch (err) {
        logger.error('app:app.module', 'unable to initialize analytics', err);
        return {} as Analytics;
      }
    }),
    provideAppCheck((injector: Injector) => {
      const appConfig: AppConfig = injector.get<AppConfig>(APP_CONFIG);
      const appCheckDebugToken: AppConfig = injector.get<AppConfig>(
        APP_CHECK_DEBUG_TOKEN
      );
      const traceService: TraceService =
        injector.get<TraceService>(TraceService);
      const doc: Document = injector.get<Document>(DOCUMENT);
      const app = getApp();
      if (appConfig.recaptcha) {
        try {
          (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckDebugToken;
          const appCheck = initializeAppCheck(app, {
            provider: new ReCaptchaEnterpriseProvider(appConfig.recaptcha),
            isTokenAutoRefreshEnabled: true,
          });
          getToken(appCheck)
            .then(() => {
              logger.info(
                'app:app.module',
                'successfully retrieved app check token'
              );
            })
            .catch((err: any) => {
              if (traceService) {
                traceService.startSpan(SpanTypes.errorReporter);
                traceService.endSpan(SpanTypes.errorReporter, err);
              }
              appCheckError(doc, traceService, appConfig);
              logger.error(
                'app:app.module',
                'unable to get app check token',
                err
              );
            });
          return appCheck;
        } catch (err) {
          logger.error('app:app.module', 'unable to initialize app check', err);
          appCheckError(doc, traceService, appConfig);
        }
      }
      if (appConfig.turnstile) {
        try {
          (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckDebugToken;
          logger.trace(
            'app:app.module',
            'initializing app check with env:',
            appConfig.turnstile,
            ' and api endpoint:',
            appConfig.api.endpoint
          );
          const cpo = new CloudflareProviderOptions(
            appConfig.turnstile,
            appConfig.api.endpoint,
            appConfig.firebase.tenantId ?? ''
          );
          const appCheck = initializeAppCheck(app, {
            provider: new CustomProvider(cpo),
            isTokenAutoRefreshEnabled: true,
          });
          logger.trace('app:app.module', 'initialized app check');
          getToken(appCheck)
            .then(() => {
              logger.info(
                'app:app.module',
                'successfully retrieved app check token'
              );
            })
            .catch((err: any) => {
              if (traceService) {
                traceService.startSpan(SpanTypes.errorReporter);
                traceService.endSpan(SpanTypes.errorReporter, err);
              }
              appCheckError(doc, traceService, appConfig);
              logger.error(
                'app:app.module',
                'unable to get app check token',
                err
              );
            });

          return appCheck;
        } catch (err: any) {
          if (traceService) {
            traceService.startSpan(SpanTypes.errorReporter);
            traceService.endSpan(SpanTypes.errorReporter, err);
          }
          appCheckError(doc, traceService, appConfig);
          logger.error('app:app.module', 'unable to initialize app check', err);
        }
      }
      logger.info('app:app.module', 'returning null for appcheck');
      return null as unknown as AppCheck;
    }),
    provideStorage((injector: Injector) => {
      const appConfig: AppConfig = injector.get<AppConfig>(APP_CONFIG);
      const storage = getStorage();
      if (appConfig.emulator) {
        // eslint-disable-next-line no-restricted-syntax
        logger.debug(
          'app:app.module',
          `connecting storage emulator at ${storageEmulatorURL.toString()} options:`,
          appConfig.firebase
        );
        connectStorageEmulator(
          storage,
          storageEmulatorURL.hostname,
          +storageEmulatorURL.port
        );
      }
      return storage;
    }),

    {
      provide: APP_INITIALIZER,
      useFactory: initializeCdkPatch,
      multi: true,
    },
    { provide: 'APP_VERSION', useValue: `${appVersion}` },
    { provide: 'ADMIN_TOOL', useValue: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
